import api from '../../utils/api'

// 授权
export async function get_info(payload) {
  console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         estimate_result: 2,
  //         estimate_result_label: '通过',
  //         doctor_name: '李明',
  //         estimate_time: new Date(),
  //         estimate_comment: '目前无痛麻醉评估结果正常，请完成心电图检查，如结果无异常可行无痛麻醉手术',
  //         items: [
  //           {
  //             title: '挂号费',
  //             price: 800,
  //             desc: '血常规是最一般，最基本的血液检验。',
  //           },
  //           {
  //             title: '非气管插管全身麻醉',
  //             price: 2000,
  //             desc: '血常规是最一般，最基本的血液检验。',
  //           },
  //           {
  //             title: '丙泊酚中/长链脂肪',
  //             price: 1400,
  //             desc: '麻醉剂',
  //           },
  //           {
  //             title: '泻药',
  //             price: 1800,
  //             desc: '血常规是最一般，最基本的血液检验。',
  //           },
  //           {
  //             title: '心电图',
  //             price: 4800,
  //             desc: '血常规是最一般，最基本的血液检验。',
  //           },
  //         ],
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/orders/anesthesia_result`, {params: payload})
}

export async function pay_order(payload) {
  return api.post(`/api/v1/mp/orders/${payload.order_id}/pay_url`, payload.info)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}

export async function address_list() {
  return api.get(`/api/v1/mp/address/list`)
}









