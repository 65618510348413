<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="order_head">
        <div class="title_box">
          <div class="order_title">{{data.estimate_result === 1?'等待在线评估':'评估结果'}}</div>
        </div>
        <p>{{data.estimate_result === 1?'等待医生进行在线麻醉评估':'详情如下'}}</p>
      </div>
      <div class="result_out" v-if="data.estimate_result">
        <div class="result_box">
          <div class="top_box">
            <div class="text_box">
              <p
                :class="[
                  'result_text',
                  {
                    orange: data.estimate_result === 1,
                    green: data.estimate_result === 2,
                    red: data.estimate_result === 3,
                  },
                ]"
              >
                <span>评估结果：</span>
                {{
                  data.estimate_result === 1
                    ? "等待评估"
                    : data.estimate_result === 2
                    ? "通过"
                    : "驳回"
                }}
              </p>
              <div v-if="order_id">
                <p>评审医生：{{ data.doctor_name }}</p>
                <p>
                  评估时间：{{
                    format_date(data.estimate_time, "yyyy年MM月dd日 HH:mm:ss")
                  }}
                </p>
              </div>
            </div>
            <div class="img_box">
              <img
                v-if="data.estimate_result === 2"
                src="../../assets/img/adopt.png"
                width="80"
                style="margin-top: -20px"
              />
              <img
                v-if="data.estimate_result === 3"
                src="../../assets/img/reject.png"
                width="80"
                style="margin-top: -20px"
              />
            </div>
          </div>
          <div v-if="order_id">
            <p class="result_text">评估意见</p>
            <p>{{ data.estimate_comment }}</p>
          </div>
          <div v-if="data.estimate_result === 1" style="margin-top: -15px">医生将在1天内评估您的身体情况，请耐心等待，请留意微信通知</div>
          <div v-else-if="data.estimate_result === 3" style="margin-top: 10px">您不符合无痛检查。请至门诊挂号咨询</div>
          <div v-else :class="['result_alert', {s2: !order_id}]">
            <div>
              <img src="../../assets/img/alert-icon.png" alt="" width="16" />
            </div>
            <p>请在检查当天先进行心电图检查拿到报告后方可进行检查</p>
          </div>
        </div>
        <div class="rescription_box" v-if="data.estimate_result === 2">
          <div class="res_title">处方单</div>
          <div
            class="res_items"
            v-for="(item, index) in data.items"
            :key="index"
          >
            <div class="res_item_left">
              <div class="res_item_title">{{ item.title }}</div>
              <div class="res_item_desc">{{ item.desc }}</div>
            </div>
            <!-- <div class="res_item_right">¥ {{ format_money(item.price) }}</div> -->
          </div>
        </div>
        <div class="address_box" v-if="data.estimate_result === 2 && data.need_address">
          <div class="address_title">
            {{
              recent_address.address
                ? "我们会将清肠剂药品邮寄到以下地址"
                : "我们会将药品邮寄给您"
            }}
          </div>
          <div class="address_sub">
            {{
              recent_address.address
                ? "深圳本市物流时间约1-2天"
                : "请设置收件地址"
            }}
          </div>
          <div class="address_info" v-if="recent_address.address">
            <div class="address_left">
              <div class="address_info_title">
                {{ recent_address.address }}
              </div>
              <div class="address_info_sub">
                {{ recent_address.recipient }} {{ recent_address.phone }}
              </div>
            </div>
            <router-link
              :to="{
                path: '/address-list',
                query: {
                  order_id: order_id,
                },
              }"
              ><div class="address_link">更换</div></router-link
            >
          </div>
          <router-link
            v-else
            :to="{
              path: '/edit-address',
              query: {
                order_id: order_id,
              },
            }"
          >
            <div class="address_link">请设置地址</div>
          </router-link>
        </div>
        <div
          class="pay_box"
          v-if="data.estimate_result === 2"
        >
          <div class="pay_out">
            <div class="pay_left">
              <div class="pay_price">
                ¥
                <p>{{ format_money(data.total_price) }}</p>
              </div>
              <div class="pay_tips">需支付金额</div>
            </div>
            <div class="pay_right">
              <van-button
                class="pay_btn"
                :disabled="!recent_address.phone"
                @click="confirm_click"
                >医保支付</van-button
              >
              <van-button
                class="pay_btn"
                :disabled="!recent_address.phone"
                @click="confirm_click"
                >自费支付</van-button
              >
            </div>
          </div>
        </div>
        <div class="back_index" v-else>
          <router-link
            :to="{
              path: '/',
            }"
          >
            <div class="back_btn">返回首页</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_info, address_list, pay_order } from "./service";
import { format_date, format_money } from "../../utils/format";
export default {
  data() {
    return {
      data: {},
      address: [],
      loading: false,
      order_id: this.$route.query.order_id,
      price: 0,
      recent_address: {
        address: this.$route.query.address,
        phone: parseInt(this.$route.query.phone) || "",
        recipient: this.$route.query.recipient,
        id_card: this.$route.query.id_card,
      },
    };
  },
  computed: {},
  components: {},
  watch: {},
  created() {
    if (!this.order_id) {
      this.data.estimate_result = 1;
      return;
    }
    this.get_list();
    if (!this.recent_address.address) {
      this.get_address();
    }
  },
  destroyed() {},
  methods: {
    format_date,
    format_money,
    async get_list() {
      this.loading = true;
      try {
        const { data } = await get_info({ order_id: this.order_id });
        this.data = data;
        //this.calc_price();
      } finally {
        this.loading = false;
      }
    },
    async get_address() {
      const { data } = await address_list();
      this.address = data;
      if (this.address.length) {
        this.recent_address = this.address[0];
      }
    },
    calc_price() {
      if (this.data.items && this.data.items.length) {
        let price = this.data.items
          .map((item) => item.price)
          .reduce((prev, next) => {
            return prev + next;
          });
        this.price = isNaN(price) ? 0 : price;
      }
    },
    async confirm_click() {
      this.loading = true;
      const payload = {
        order_id: this.order_id,
        info: {
          phone: `${this.recent_address.phone}`,
          name: this.recent_address.recipient,
          address: this.recent_address.address,
          id_card: this.recent_address.id_card
        },
      };
      try {
        const { data } = await pay_order(payload);
        window.open(data.content.payUrl, "_blank");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped >
.order_head {
  padding: 31px 17px 27px;
  background: url("../../assets/img/family-bg.png") right center no-repeat
    #000ca8;
  background-size: contain;
  color: #ffffff;
  font-size: 12px;
  line-height: 17px;
}

.title_box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
}

.order_title {
  font-size: 24px;
  font-weight: 600;
  line-height: 34px;
  color: #ffffff;
}

.pay_box {
  position: fixed;
  width: 100%;
  z-index: 1;
  height: 54px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  bottom: 0;
  display: flex;
  align-items: center;
  padding: 0px 16px;
  justify-content: space-between;
}

.pay_out {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.pay_price {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 19px;
  color: #ff6600;

  & p {
    font-size: 19px;
    font-weight: 600;
    padding-left: 2px;
  }
}

.pay_tips {
  font-size: 10px;
  line-height: 14px;
  color: #707070;
}

.pay_right {
  display: flex;
}

.pay_btn {
  width: 100px;
  height: 32px;
  background: #0088ff;
  border-radius: 21px;
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;
  text-align: center;
  margin-left: 8px;
}

.g_main_content {
  background: #f7f7f7;
  padding-bottom: 74px;
}

.result_box {
  padding: 23px 18px;
  background: #fff;
  font-size: 14px;
  line-height: 18px;
  color: #565656;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
}

.top_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
}

.text_box p {
  padding-bottom: 10px;
}

.result_text {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 10px;

  & span {
    color: #565656;
  }

  &.green {
    color: #00c43c;
  }
  &.red {
    color: red;
  }
  &.orange {
    color: orange;
  }
}

.result_alert {
  margin-top: 25px;
  background: #feeded;
  padding: 6px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 16px;
  color: #df6363;
  display: flex;
  justify-content: space-between;

  & p {
    margin-left: 5px;
  }

  &.s2 {
    margin-top: 0;
  }
}

.rescription_box {
  margin-top: 20px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
  padding: 16px 17px 25px 17px;
  background: #fff;
}

.res_title {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  color: #565656;
  padding-bottom: 5px;
}

.res_items {
  margin-top: 11px;
  background: #f4f9fe;
  padding: 14px 17px;
  border-radius: 10px;
  color: #242938;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.res_item_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.res_item_desc {
  font-size: 10px;
  line-height: 14px;
  color: #454545;
}

.res_item_right {
  font-size: 14px;
}

.address_box {
  padding: 14px 18px;
  color: #565656;
  margin-top: 20px;
  background: #fff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.04);
}

.address_title {
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  margin-bottom: 2px;
}

.address_sub {
  font-size: 14px;
  line-height: 16px;
}

.address_link {
  min-width: 60px;
  height: 30px;
  margin-top: 12px;
  padding: 6px 16px;
  color: #fff;
  font-size: 14px;
  line-height: 17px;
  background: #0088ff;
  border-radius: 21px;
  display: inline-block;
}

.address_info {
  margin-top: 12px;
  padding: 11px 13px;
  background: #f4f9fe;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.address_info_title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #262626;
}

.address_info_sub {
  font-size: 12px;
  line-height: 19px;
  color: #565656;
  margin-top: 5px;
}

.back_index {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 76px;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  padding: 10px 21px;
}

.back_btn {
  height: 40px;
  background: #0088ff;
  border-radius: 9px;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  color: #ffffff;
  text-align: center;
}
</style>
